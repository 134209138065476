<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card class="elevation-12" style="max-width: 400px;margin: 0% auto">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>退出</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            成功退出
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" to='/auth/signin'>去登录</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import { event_emit } from '@/eventbus';
    import gdata from '@/gdata';
    export default {
        mounted: function () {
            window.localStorage.removeItem('me');
            gdata.medata = null;
            event_emit(this, 'signout');
        },
    }
</script>